(function ($, window, document, undefined) {

    'use strict';

    $(function () {

        var full = window.screen.availHeight;
        var shown = window.innerHeight;
        //alert('full: ' + full + ' avail: '+ shown);



        function hideAllBut(flip) {
            $(flip).show().siblings().hide();
        }

        function initalflips(){
            $('.flip-container').removeClass('hover').hide();
            $('#circle1, #circle2, #circle3').show();
        }


        //nav
        $('.navtoggle').on('click', function() {
            var wwidth = window.innerWidth;
            if(wwidth < 480){
                $('.logo').hide();
            }
            console.log(window.innerWidth);
            $(this).hide();
            $('.nav ul').toggleClass('show');
        });

        $('.nav ul li a').click(function (e) {
            var showpanel = $(this).attr('data-target');
            var showflip = $(this).attr('data-flip');
            if(showflip!==''){
                hideAllBut(showflip);
            }
            if(showpanel){
                e.preventDefault();
                $('.nav ul li a').removeClass('active');
                $(this).addClass('active');
                $('#wrapper').removeClass().addClass(''+ showpanel);
                //console.log(showpanel);
                // load appropriate content panel
                $('.contentpanel').removeClass('in');
                //if(showpanel == 'kontakt' || showpanel == 'referenzen'){
                    $('#' + showpanel).addClass('in');
                //}


            }
            //$('.nav ul').toggleClass('show');
            //$('.navtoggle').show();

        });

        $('#imprint, .privacy-policy-link').click(function (e) {
            e.preventDefault();
            $('.contentpanel').removeClass('in');
            $('#impressum').addClass('in');
        });

        $('a.intern-link').click(function (e) {
            e.preventDefault();
            var panel = $(this).attr('data-target');
            var currentPanel = $('.contentpanel.in');
            var currentPanelID = currentPanel.attr('id');


            currentPanel.removeClass('in');
            $('#' + panel).addClass('in');
            $('#wrapper').removeClass().addClass(''+ panel);
            $('#' + panel).find('.contentpanel-back').attr('data-target', currentPanelID);
        });

        $('.contentpanel-back').on('click', function(){
            var panel = $(this).attr('data-target');
            var currentPanel = $('.contentpanel.in');

            currentPanel.removeClass('in');
            $('#' + panel).addClass('in');
            $('#wrapper').removeClass().addClass(''+ panel);
        });

// flips
        $('#circle4, #circle5').hide();
        $('.flip-container').bind('click touchstart', function(){
            $(this).addClass('hover');
        }).bind('touchend', function(){
            $(this).removeClass('hover');
        });


        $('.flipper .back').on('click', function(e){
            e.preventDefault();
            var panel = $('a', this).attr('href');
            var navstr = panel.substring(1, panel.length);
            $('.nav ul li a').removeClass('active');
            $("[data-target='" + navstr + "']").addClass('active');
            // console.log('linkhref: ' + navstr);

            $(panel).addClass('in');
            $('body').addClass('freeze');
            $('#wrapper').removeClass().addClass(''+ navstr);
            $(this).closest('.flip-container').siblings().fadeOut(1000);
        });

        $('.link_zu_kontakt').on('click', function(e){
            e.preventDefault();
            $(this).addClass('hoihoi');
            var panel = $(this).attr('href');
            var navstr = panel.substring(1, panel.length);
            $('.nav ul li a').removeClass('active');
            $("[data-target='" + navstr + "']").addClass('active');
            $('body').addClass('freeze');
            $('#wrapper').removeClass().addClass(''+ navstr);
            $('.contentpanel').removeClass('in');
            $(panel).addClass('in');
        });



// content panels
        $('.contentpanel-close').on('click', function(){
            initalflips();
            $('.navtoggle').hide();
            $('.nav ul').addClass('show');
            $(this).parent().toggleClass('in');
            $('body').toggleClass('freeze');
            //$('.flip-container').fadeIn(1000);
        });



// contact form
        $('#contactform').submit( function(e){

            e.preventDefault();

            var form = $(this);
            var url = form.attr('action');
            var type = form.attr('method');
            var data = form.serialize();

            $.ajax({
                dataType: "json",
                type: type,
                url: url,
                data: data,
                success: function(data) {

                    // show response from the php script.
                    console.log(data);

                    $('.contactform-row-feedback').addClass('show');

                    if ( data.errors.length > 0 ) {

                        var backgroundColor = 'bisque';

                        // prepare the feedback-string
                        var feedback = '';
                        data.errors.forEach( function( entry, index ){
                            feedback += entry;
                            if ( index != data.errors.length - 1 ) { feedback += '<br/>'; }
                        });

                        if ( data.antispamstatus === false ) {
                            $('.contactform-row-input-antispam').val('');
                        }

                    } else { // no errors --> email sent

                        var backgroundColor = 'palegreen';
                        var feedback = data.success;

                        // rewrite antispam
                        $('.contactform-row-input-antispam').attr('placeholder', data.antispamtext).val('');
                        $('#numberfirst').attr('value', data.post.numberfirst);
                        $('#numbersecond').attr('value', data.post.numbersecond);

                    }

                    $('.contactform-row-feedback').css('background-color', backgroundColor).html(feedback);

                },
                error: function (data) {

                    // show response from the php script.
                    console.log(data);

                    var backgroundColor = 'bisque';
                    var feedback = 'Das Formular konnte aus technischen Gründen nicht gesendet werden. Bitte versuchen Sie es nochmals.';

                    $('.contactform-row-feedback').css('background-color', backgroundColor).html(feedback);

                },
            });

        });


    });

})(jQuery, window, document);


